import InfoBankCompany from "./InfoBankCompany";
import InfoCompanyBasic from "./InfoCompanyBasic";
import React, { useEffect, useState } from "react";
import ShippingAddressRegistration from "./ShippingAddressRegistration";
import SuperAccount from "./SuperAccount";
import moment from "moment";
import { Breadcrumb, Tabs, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";
import { Link } from "gatsby";
import { navigate } from "gatsby";
import { getStoreById } from "../../../services/user";

const { TabPane } = Tabs

const SuperListCompanyDetail = ({ storeId }) => {
  const [stores, setStores] = useState()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (storeId) {
      ;(async function () {
        setLoading(true)
        const { data } = await getStoreById(storeId)
        console.log("dataaaa", data)
        setLoading(false)
        if (data.stores) {
          const { address, founded_at, wholesale_companies } = data.stores
          const customStores = { ...data.stores }
          if (address) {
            const desc = `${address?.region} ${address?.city} ${
              address?.town
            } ${address?.building_name ?? ""}`
            customStores.address.desc = desc
          }

          if (founded_at) {
            customStores.founded_at = {
              year: moment(founded_at),
              month: moment(founded_at),
            }
          }
          if (wholesale_companies) {
            const array = Object.entries(wholesale_companies)?.map(
              (item) => item[0]
            )
            console.log(array)
            customStores.list_wholesale = array
          }
          setStores({
            ...customStores,
          })
        } else {
          navigate("/super-list-company/?pageCurrent=1&pageSize=10")
        }
      })()
    }
  }, [storeId])

  const props = {
    storeId,
    stores,
    loading,
    setLoading,
  }

  return (
    <div>
      <Content>
        <div
          style={{
            backgroundColor: "#FFFFFF",
            padding: "10px",
          }}
        >
          <Breadcrumb separator={">"}>
            <Breadcrumb.Item route="/home">
              <Link to="/home">HOME</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item route="/company-info">掲載管理</Breadcrumb.Item>
            <Breadcrumb.Item>企業詳細</Breadcrumb.Item>
          </Breadcrumb>

          <Typography.Title
            level={2}
            className="!text-2xl !pt-6 !font-normal !leading-[33px]] !tracking-[0.3px]"
          >
            企業詳細
          </Typography.Title>
        </div>
        <div className=" border-t border-[rgba(0,0,0,0.1)]">
          <Tabs defaultActiveKey="1" size="large">
            <TabPane tab="基本情報" key="1">
              <InfoCompanyBasic {...props} />
            </TabPane>
            <TabPane tab="店舗情報発送先登録" key="2">
              <ShippingAddressRegistration {...props} />
            </TabPane>
            <TabPane tab="口座情報" key="3">
              <InfoBankCompany {...props} />
            </TabPane>
            <TabPane tab="支払い情報" key="4">
              <InfoBankCompany {...props} />
            </TabPane>
            <TabPane tab="アカウント一覧" key="5">
              <SuperAccount {...props} />
            </TabPane>
          </Tabs>
        </div>
      </Content>
    </div>
  )
}

export default SuperListCompanyDetail
