import { DatePicker, Form, Input, Space, Tag } from "antd"
import React from "react"
import { onKeyPressNumber } from "../../../../customUtil/global"
import textAndRules from "../../../../utils/textAndRules"

const FormDateCompany = ({ label }) => {
  return (
    <Form.Item
      label={
        <div className="flex">
          <Tag style={{ display: "inline" }} color="red">
            必須
          </Tag>
          <div className="font-medium font-notoSans text-[#333]">{label}</div>
        </div>
      }
    >
      <Space style={{ width: "100%" }} className="customSpace">
        <Form.Item
          name={["founded_at", "year"]}
          rules={[
            {
              required: true,
              message: textAndRules.pleaseInput,
            },
          ]}
        >
          <DatePicker
            style={{ width: "5rem" }}
            format={"YYYY"}
            picker="year"
            placeholder="2000"
          />
        </Form.Item>
        <span className="text-13 text-[#888]">年</span>
        <Form.Item
          name={["founded_at", "month"]}
          rules={[
            {
              required: true,
              message: textAndRules.pleaseInput,
            },
          ]}
        >
          <DatePicker
            style={{ width: "5rem" }}
            placeholder="1"
            picker="month"
            format={"MM"}
          />
        </Form.Item>
        <span className="text-13 text-[#888]">月</span>
      </Space>
    </Form.Item>
  )
}

export default React.memo(FormDateCompany)
