import { Form, Tag, Space, Input } from "antd"
import React, { useRef } from "react"
import { apiAddress, onKeyPressNumber } from "../../../../customUtil/global"
import axios from "axios"
import { openNotificationFail } from "../../../../components/notification"
import textAndRules from "../../../../utils/textAndRules"

const FormAddressInfoCompany = ({ form, setLoading }) => {
  const number2AddressRef = useRef()
  const descAddressRef = useRef()

  const handleGetRegion = async (e) => {
    if (e.target.value.length === 4) {
      setLoading(true)
      const zipCode = form.getFieldValue(["address", "num1"]) + e.target.value
      const { data } = await axios.get(apiAddress(zipCode))
      setLoading(false)
      if (data.results) {
        const results = data.results[0]
        const desc = `${results?.address1} ${results?.address2} ${results?.address3} `
        form.setFieldsValue({
          address: {
            desc,
          },
        })
        descAddressRef.current.focus()
      } else {
        openNotificationFail(
          "郵便番号から住所が見つかりませんでした。",
          "",
          "red"
        )
      }
    }
  }

  return (
    <Form.Item
      label={
        <div className="flex">
          <Tag style={{ display: "inline" }} color="red">
            必須
          </Tag>
          <div className="font-medium font-notoSans text-[#333]">住所</div>
        </div>
      }
    >
      <Space style={{ width: "100%" }} className="customSpace">
        <Form.Item
          name={["address", "num1"]}
          rules={[
            {
              required: true,
              message: textAndRules.pleaseInput,
            },
          ]}
        >
          <Input
            placeholder="000"
            className="!w-[90px]"
            addonBefore="〒"
            maxLength={3}
            onKeyPress={onKeyPressNumber}
            onChange={(e) => {
              if (e.target.value.length > 2) {
                number2AddressRef.current.focus()
              }
            }}
          />
        </Form.Item>
        <Form.Item
          name={["address", "num2"]}
          rules={[
            {
              required: true,
              message: textAndRules.pleaseInput,
            },
          ]}
        >
          <Input
            placeholder="0001"
            className="!w-[100px]"
            addonBefore="－"
            maxLength={4}
            onKeyPress={onKeyPressNumber}
            ref={number2AddressRef}
            onChange={handleGetRegion}
          />
        </Form.Item>
        <Form.Item
          name={["address", "desc"]}
          rules={[
            {
              required: true,
              message: textAndRules.pleaseInput,
            },
            {
              whitespace: true,
              message: "空白を入力できません。",
            },
          ]}
        >
          <Input placeholder="東京都渋谷区恵比寿西" ref={descAddressRef} />
        </Form.Item>
      </Space>
    </Form.Item>
  )
}

export default React.memo(FormAddressInfoCompany)
