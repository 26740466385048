import { Button, Form } from "antd"
import React, { useState } from "react"
import FormAddressInfoCompany from "./FormAddressInfoCompany"
import FormDateCompany from "./FormDateCompany"
import FormInputOne from "./FormInputOne"
import FormInputTwo from "./FormInputTwo"
import FormPhoneNumber from "./FormPhoneNumber"
import { formatPhoneNumber, isValidPhoneNumber } from "react-phone-number-input"

const FormInput = (props) => {
  const [inputPhone, setInputPhone] = useState(
    formatPhoneNumber(props.stores?.phone_company) || null
  )
  return (
    <>
      {props.stores && (
        <Form
          form={props.form}
          name={props.name}
          className="w-full"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={props.stores}
          onChange={(e) => {
            console.log(e.target.id)
            if (e.target.id == "form1_name") {
              props.convertText(e.target.value, "form1")
            }
            if (e.target.id == "form4_rep_name") {
              props.convertText(e.target.value, "form4_rep_name")
            }
            if (e.target.id == "form4_rep_name_first") {
              props.convertText(e.target.value, "form4_rep_name_first")
            }
            if (e.target.id == "form6_phone_company") {
              if (e.target.value) {
                setInputPhone(e.target.value)
              }
            }
          }}
        >
          <div className="flex items-center border-b border-b-[rgba(0,0,0,0.06)] pb-5 mt-3">
            <div className="flex-1 pr-7 mr-4 border-r border-r-[rgba(204,204,204,0.5)]">
              {props.type === "address" ? (
                <FormAddressInfoCompany
                  form={props.form}
                  setLoading={props.setLoading}
                />
              ) : props.type === "second" ? (
                <FormInputTwo
                  kana={props.kana}
                  label={props.label}
                  name={props.nameItem}
                  nameFist={props.nameItemFirst}
                  placeholder={props.placeholder}
                  placeholderFirst={props.placeholderFirst}
                />
              ) : props.type === "phone" ? (
                <FormPhoneNumber label={props.label} inputPhone={inputPhone} />
              ) : props.type === "date" ? (
                <FormDateCompany label={props.label} />
              ) : (
                <FormInputOne
                  kana={props.kana}
                  type={props.type}
                  nameItem={props.nameItem}
                  required={props.required}
                  label={props.label}
                  placeholder={props.placeholder}
                  formatter={props.formatter}
                  parser={props.parser}
                  maxLength={props.maxLength}
                />
              )}
            </div>
            <Button danger htmlType="submit">
              編集
            </Button>
          </div>
        </Form>
      )}
    </>
  )
}

export default React.memo(FormInput)
