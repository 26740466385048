import { Button, Input, Select, Space } from "antd"
import React from "react"

const HeaderSuperAccount = () => {
  return (
    <div className="flex p-[20px_10px_10px_10px] justify-between h-[62px] bg-[#f3f3f3]">
      <Space>
        <Select
          defaultValue="削除"
          style={{
            width: 120,
          }}
          options={[
            {
              value: "削除",
              label: "削除",
            },
          ]}
        />
        <Button type="danger" className="!w-[115px]">
          実施
        </Button>
      </Space>
      {/* <div className="flex items-center">
        <Button type="danger" className="!w-[200px] mr-5">
          ＋ アカウント追加
        </Button>
        <Input placeholder="氏名で探す" className="!w-[244px]" />
        <Button danger className="ml-[5px]">
          検索
        </Button>
      </div> */}
    </div>
  )
}

export default HeaderSuperAccount
