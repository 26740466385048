import React from "react"
import Topbar from "../../../components/organisms/topbar"
import CustomLayout from "../../../Layouts/Layout"
import { getSearchParams } from "gatsby-query-params"
import "./style.scss"
import SuperListCompanyDetail from "../../../domain/superListCompany/superListCompanyDetail"

const Detail = () => {
  const query = getSearchParams()
  console.log(query)
  return (
    <div className="customContent">
      <Topbar />
      <CustomLayout
        selectedPage={<SuperListCompanyDetail storeId={query?.storeId} />}
      />
    </div>
  )
}

export default Detail
