import InfoBankCompany from "./InfoBankCompany";

export const dataForm = [
  {
    name: "form1",
    nameItem: "bank_name",
    label: "銀行名",
    placeholder: "みずほ銀行",
  },
  {
    name: "form2",
    nameItem: "branch_name",
    label: "支店名",
    placeholder: "新宿新都心支店",
  },
  {
    type: "number",
    maxLength: 9,
    // formatter: (value) =>
    //   `${value}`.replace(/\D+/g, "").replace(/(\d{2})(\d{7})/, "$1-$2"),
    // parser: (value) => value.replace(/\$\s?|(-*)/g, ""),
    name: "form3",
    nameItem: "branch_number",
    label: "支店番号",
    placeholder: "62-5411700",
  },
  {
    type: "select",
    name: "form4",
    nameItem: "account_type",
    label: "口座種別",
    placeholder: "普通",
  },
  {
    type: "number",
    maxLength: 8,
    name: "form5",
    nameItem: "account_number",
    label: "口座番号",
    placeholder: "11002582",
  },
  {
    name: "form6",
    nameItem: "account_holder",
    label: "口座名義",
    placeholder: "山田",
  },
  {
    name: "form7",
    kana: true,
    nameItem: "account_name_kana",
    label: "口座名義 カナ",
    placeholder: "ヤマダ",
  },
]

export default InfoBankCompany
