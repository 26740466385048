import { Table, Button, Space } from "antd"
import React, { useContext, useEffect, useState } from "react"
import { paginationCustom } from "../../../../components/paginationCustom"
import { AccountContext } from "../../../../context/account"
import { getListUser } from "../../../../services/user"
import HeaderSuperAccount from "./HeaderSuperAccount"
import { formatPhoneNumber } from "react-phone-number-input"

const SuperAccount = ({ stores, loading, setLoading }) => {
  const { isRoot } = useContext(AccountContext)
  const [listAccount, setListAccount] = useState({
    data: [],
    total: 0,
  })
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [page, setPage] = useState({
    skip: 0,
    take: 10,
  })

  const columns = [
    {
      title: "氏名",
      dataIndex: "name",
      width: "10%",
    },
    {
      title: "電話番号",
      dataIndex: "phone",
      width: "15%",
    },
    {
      title: "メールアドレス",
      dataIndex: "email",
    },
    {
      title: "権限",
      dataIndex: "role",
      width: "10%",
      render: (text) => (
        <>
          <Button
            style={{
              color:
                text == "マスター管理者"
                  ? "#FB053B"
                  : text == "管理者"
                  ? "#FA7743"
                  : text == "スタッフ（発注可）"
                  ? "#2F54EB"
                  : "#9064C0",
              backgroundColor:
                text == "マスター管理者"
                  ? "rgb(255, 240, 240)"
                  : text == "管理者"
                  ? "rgb(255, 239, 224)"
                  : text == "スタッフ（発注可）"
                  ? "#F0F5FF"
                  : "#F9F0FF",
              width: "100%",
            }}
          >
            {text.toUpperCase()}
          </Button>
        </>
      ),
    },
    {
      title: "操作",
      width: "5%",
      render: (record) => {
        // console.log(record)
        return (
          <Space>
            <Button
              disabled={!isRoot}
              danger
              //   onClick={() =>
              //     navigate(
              //       `/company-info/register/handleCompanyInfo/${record.key}`
              //     )
              //   }
            >
              編集
            </Button>
            <Button
              disabled={!isRoot || record.isRoot}
              style={{
                border: "1px solid rgba(34, 34, 34, 0.7)",
                color: "rgba(34, 34, 34, 0.7)",
              }}
              //   onClick={() => handleDelete(record.key)}
            >
              削除
            </Button>
          </Space>
        )
      },
    },
  ]

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys)
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      const { data } = await getListUser(stores.id, page)
      setLoading(false)
      console.log(data)
      if (data) {
        const dataTable = data.users?.map((data) => {
          let roles = ""
          const phoneNumberArray = []
          if (data.role == "admin") {
            roles = data.isRoot ? "マスター管理者" : "管理者"
          } else if (data.role == "developer") {
            roles = "スタッフ（発注可）"
          } else {
            roles = "スタッフ（発注不可）"
          }
          let phone
          if (data.metadata?.phone) {
            phone = formatPhoneNumber(data.metadata?.phone)
          }
          return {
            key: data.id,
            name: `${data.first_name} ${data.last_name}`,
            email: data.email,
            role: roles,
            phone: phone ? phone : "-",
            isRoot: data.isRoot,
          }
        })
        console.log(dataTable)
        setListAccount({
          data: dataTable,
          total: data.total,
        })
      }
    })()
  }, [])

  return (
    <div>
      <HeaderSuperAccount />
      <Table
        columns={columns}
        loading={loading}
        dataSource={listAccount.data}
        rowSelection={rowSelection}
        scroll={{ x: 1100 }}
        rowKey="id"
        pagination={{
          total: listAccount.total,
          itemRender: paginationCustom,
          showSizeChanger: false,
          showTotal: (total, range) => (
            <div>
              {range[0]}~{range[1]} 件 表示 &nbsp;&nbsp;&nbsp; (全
              {listAccount.total}件)
            </div>
          ),
        }}
      />
    </div>
  )
}

export default SuperAccount
